<template>
    <div>
        <template>
            <v-data-table :headers="tables.contractPeriods.headers" :items="contractPeriods" hide-default-footer
                disable-pagination>
                <template v-slot:top>
                    <v-tooltip top v-if="!isOrganization&&$store.getters.userRole>=roles.nationalCoordinator">
                        Hozzáadás
                        <template v-slot:activator="{on, attrs}">
                            <v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialog.show=true">
                                <v-icon>fa-plus</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>
                </template>
                <template v-slot:item.view="{ item }">
                    <v-btn icon :href="$router.resolve({ name: 'contractPeriod', params: { id: item.id } }).href"
                        target="_blank">
                        <v-icon>fa-eye</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-row justify="center">
                <v-dialog v-model="dialog.show" persistent max-width="600px">

                    <v-card>
                        <v-card-title>
                            <span class="headline">Új időszak</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-menu v-model="dialog.menus.begin" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dialog.contractPeriod.begin" label="Kezdő időpont"
                                            prepend-inner-icon="fa-calendar" readonly v-bind="attrs" v-on="on" rounded
                                            outlined />
                                    </template>
                                    <v-date-picker v-model="dialog.contractPeriod.begin" :first-day-of-week="1"
                                        @input="dialog.menus.begin = false" />
                                </v-menu>
                                <v-menu v-model="dialog.menus.end" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dialog.contractPeriod.end" label="Befejező időpont"
                                            prepend-inner-icon="fa-calendar" readonly v-bind="attrs" v-on="on" rounded
                                            outlined />
                                    </template>
                                    <v-date-picker v-model="dialog.contractPeriod.end" :first-day-of-week="1"
                                        @input="dialog.menus.end = false" />
                                </v-menu>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog.show = false">
                                Mégsem
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="create()"
                                v-if="dialog.contractPeriod.begin && dialog.contractPeriod.end">
                                Hozzáadás
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>

        </template>
    </div>
</template>

<script>
import roles from '../plugins/roles'

export default {
    props: ["clubId"],
    data: function () {
        return {
            roles,
            contractPeriods: [],
            options: {

            },
            tables: {
                contractPeriods: {
                    headers: [
                        {
                            text: 'Kezdet',
                            align: "center",
                            sortable: false,
                            filterable: false,
                            value: "begin"
                        },
                        {
                            text: 'Vége',
                            align: "center",
                            sortable: false,
                            filterable: false,
                            value: "end"
                        }
                    ]
                }
            },
            dialog: {
                show: false,
                menus: {
                    begin: false,
                    end: false
                },
                contractPeriod: {
                    begin: '',
                    end: '',
                }
            }
        }
    },
    computed: {
        isOrganization() {
            return this.clubId != null;
        }
    },
    watch: {

    },
    methods: {
        create: function () {
            this.axios({ url: "contractPeriod", method: "POST", data: { contractPeriod: this.dialog.contractPeriod } }).then((response) => {
                if (response.data.success) {
                    this.contractPeriods.push({ ...this.dialog.contractPeriod, id: response.data.data.id });
                    this.dialog.show = false;
                    this.dialog.contractPeriod = { begin: '', end: '' }
                    this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.')
                }
            })
        },
        getContractPeriods: function () {
            this.axios({ url: "contractPeriod", method: "GET", params: this.options }).then((response) => {
                if (response.data.success) {
                    this.contractPeriods = response.data.data.contractPeriods
                }
            })
        }
    },
    mounted() {
        if (this.clubId) this.options.clubId = this.clubId;
        this.getContractPeriods()
        if (!this.isOrganization) this.tables.contractPeriods.headers.push({
            text: "Megtekintés",
            align: "center",
            sortable: false,
            filterable: false,
            value: "view"
        })
    }
}
</script>
