var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"headers":_vm.tables.contractPeriods.headers,"items":_vm.contractPeriods,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.isOrganization&&_vm.$store.getters.userRole>=_vm.roles.nationalCoordinator)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"success"},on:{"click":function($event){_vm.dialog.show=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-plus")])],1)]}}],null,false,978897272)},[_vm._v(" Hozzáadás ")]):_vm._e()]},proxy:true},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":_vm.$router.resolve({ name: 'contractPeriod', params: { id: item.id } }).href,"target":"_blank"}},[_c('v-icon',[_vm._v("fa-eye")])],1)]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Új időszak")])]),_c('v-card-text',[_c('v-container',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Kezdő időpont","prepend-inner-icon":"fa-calendar","readonly":"","rounded":"","outlined":""},model:{value:(_vm.dialog.contractPeriod.begin),callback:function ($$v) {_vm.$set(_vm.dialog.contractPeriod, "begin", $$v)},expression:"dialog.contractPeriod.begin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog.menus.begin),callback:function ($$v) {_vm.$set(_vm.dialog.menus, "begin", $$v)},expression:"dialog.menus.begin"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.dialog.menus.begin = false}},model:{value:(_vm.dialog.contractPeriod.begin),callback:function ($$v) {_vm.$set(_vm.dialog.contractPeriod, "begin", $$v)},expression:"dialog.contractPeriod.begin"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Befejező időpont","prepend-inner-icon":"fa-calendar","readonly":"","rounded":"","outlined":""},model:{value:(_vm.dialog.contractPeriod.end),callback:function ($$v) {_vm.$set(_vm.dialog.contractPeriod, "end", $$v)},expression:"dialog.contractPeriod.end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog.menus.end),callback:function ($$v) {_vm.$set(_vm.dialog.menus, "end", $$v)},expression:"dialog.menus.end"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.dialog.menus.end = false}},model:{value:(_vm.dialog.contractPeriod.end),callback:function ($$v) {_vm.$set(_vm.dialog.contractPeriod, "end", $$v)},expression:"dialog.contractPeriod.end"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v(" Mégsem ")]),(_vm.dialog.contractPeriod.begin && _vm.dialog.contractPeriod.end)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Hozzáadás ")]):_vm._e()],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }